<template>
    <div id="case-detail">
        <div class="md-layout md-gutter md-alignment-top-center">
            <div class="md-layout-item md-size-80">
                <div v-if="detail.loadStatus == 'LOADED'">
                    <div class="md-layout md-alignment-top-space-between">
                        <div class="md-layout-item md-size-40 details-headline">
                            <p><strong>Candidate:</strong> {{ detail.data.candidate_first_name }} {{ detail.data.candidate_last_name }}</p>
                            <p><strong>Email:</strong> <a v-bind:href="'mailto:' + detail.data.candidate_email">{{ detail.data.candidate_email }}</a></p>
                        </div>
                        <div class="md-layout-item md-size-40 details-headline-right">
                            <p><strong>Case Type:</strong> {{ detail.data.packet_template_name }}</p>
                            <p><strong>Case Created:</strong> {{ formatDate(detail.data.created_at) }}</p>
                        </div>
                        <div class="md-layout-item md-size-100" v-if="showUrl">
                            <h2><a target="_blank" :href="caseUrl">{{caseUrl}}</a><md-icon class="inline-header-icon">open_in_new</md-icon></h2>
                        </div>
                    </div>
                    <md-divider></md-divider>
                    <h1>Case Status</h1>
                    <div v-for='step in workflowSteps' v-bind:key='step.id' v-bind:value='step.name'>
                        <div class="clickable-toolbar" @click="switchVisibleStep(step.id)">
                            <md-toolbar :class="getStepClass(step)" md-elevation="0">
                                <div class="md-toolbar-section-start">
                                    <md-button class="md-icon-button" @click="switchVisibleStep(step.id)">
                                        <md-icon>{{ getStepIcon(step) }}</md-icon>
                                    </md-button>
                                    <h2 class="md-title" :id="castToString(step.id)">{{ step.name }} - {{ getStepLabel(step) }}</h2>
                                </div>
                            </md-toolbar>
                        </div>
                        <div v-if="showStep(step.id)" class="md-layout md-gutter md-alignment-top-center">
                            <div v-if='step.committees.length > 0' class="md-layout-item md-size-90" >
                                <div v-for='comm in step.committees' v-bind:key='comm.id' v-bind:value='comm.name'>
                                    <p class="details-headline">
                                        <span class="md-headline">{{ comm.name }} Committee Members:</span>
                                    </p>
                                    <div v-if="comm.committee_members.length > 0">
                                        <md-list class="md-double-line">
                                            <md-list-item v-for='member in comm.committee_members' v-bind:key='member.id' v-bind:value='member.email'>
                                                <div class="md-list-item-text">
                                                    <span>{{ member.first_name}} {{ member.last_name }}</span>
                                                    <p><a v-bind:href="'mailto:' + member.email">{{ member.email }}</a></p>
                                                </div>
                                            </md-list-item>
                                        </md-list>
                                    </div>
                                    <div v-else>
                                        <p class="details-headline">
                                            <span class="md-subheading">This committee has no members.</span>
                                        </p>
                                    </div>
                                  <div v-if="currentStep == step.id && userCanOmitFormRequirements(comm)">
                                    <md-button v-if="omitUnsubmittedFormsStatus !== LOAD_STATUS.LOADING" class="md-primary md-raised" @click="omitCommitteeFormRequirements(step, comm)">omit unsubmitted committee form requirements</md-button>
                                    <md-progress-spinner :md-diameter="30" :md-stroke-width="3" v-if="omitUnsubmittedFormsStatus == LOAD_STATUS.LOADING" md-mode="indeterminate"></md-progress-spinner>
                                  </div>
                                </div>
                            </div>
                            <div v-else class="md-layout-item md-size-90" >
                                <span class="md-display-1">There are no committees assigned to this workflow step.</span>
                            </div>
                        </div>
                      <md-divider></md-divider>
                    </div>
                </div>
                <div v-else>
                    <h1>Loading Case Details</h1>
                    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment'
import {LOAD_STATUS} from '@/store/store-utils'

export default {
    name: 'CaseDetail',
    props: ['caseId'],
    data: () => ({
        showSnackbar: true,
        selectedStep: "",
        LOAD_STATUS
    }),
    created: function() {
        this.$store.dispatch('interfolio/loadCaseDetail', this.caseId);
    },
    computed: {
        interfolioUser() {
          return this.$store.getters['user/interfolioUser'];
        },
        detail() {
            return this.$store.getters['interfolio/caseDetail'](this.caseId);
        },
        isAdmin() {
          return this.$store.getters['user/isAdmin'];
        },
        workflowSteps() {
            return this.detail.data.workflow_steps.slice(1);
        },
        currentStep() {
            return this.detail.data.current_workflow_step.id + "";
        },
        caseUrl() {
            return process.env.VUE_APP_INTERFOLIO_CASE_URL + this.detail.data.id;
        },
        omitUnsubmittedFormsStatus() {
          return this.$store.getters['interfolio/omitUnsubmittedFormsStatus'];
        },
        showUrl() {
            let comms = this.$store.getters['user/interfolioUserCommittees'];
            for (let comm of comms) {
                if (comm.name == "School Faculty Affairs") return true;
                for (let stepComm of this.detail.data.current_users_assigned_committees) {
                    if (stepComm.id == comm.id) return true;
                }
            }
            return false;
        }
    },
    methods: {
        omitCommitteeFormRequirements(step, committee) {
          this.$store.dispatch("interfolio/omitCommitteeFormRequirements", {packetId: this.caseId, workflowStepId: step.id, committeeId: committee.id});
        },
        userCanOmitFormRequirements(committee) {
          if(this.isAdmin) return true;
          for(let committee_member of committee.committee_members) {
            if (
                committee_member.pid === parseInt(this.interfolioUser.pid)
                && committee_member.manager === true
              ) {
              return true;
            }
          }
          return false;
        },
        dismissError(index) {
            this.$store.dispatch('error/dismissError', index);
        },
        castToString(num) {
            return "" + num;
        },
        getStepClass(step) {
            if (step.step_number < this.detail.data.current_workflow_step.step_number) {
                return 'md-transparent';
            }
            if (step.committees.length == 0) {
                return 'md-accent';
            }
            for (let comm of step.committees) {
                if (comm.committee_member_count == 0) {
                    return 'md-accent';
                }
            }
            if (step.current) {
                return 'md-primary';
            }
            return 'md-transparent';
        },
        getStepIcon(step) {
            if (step.step_number < this.detail.data.current_workflow_step.step_number) {
                return 'check_box';
            }
            if (step.committees.length == 0) {
                return 'warning';
            }
            for (let comm of step.committees) {
                if (comm.committee_member_count == 0) {
                    return 'warning';
                }
            }
            if (this.currentStep == step.id) {
                return 'edit';
            } else {
                return 'check_box_outline_blank';
            }
        },
        showStep(stepId) {
            if (this.selectedStep != "" && this.selectedStep == stepId) {
                return true;
            } else if (this.selectedStep == "" && stepId == this.currentStep) {
                return true;
            }
            return false;
        },
        switchVisibleStep(stepId) {
            this.selectedStep = stepId;
        },
        getStepLabel(step) {
            if (step.step_number < this.detail.data.current_workflow_step.step_number) {
                return 'Completed';
            }
            if (step.committees.length == 0) {
                return 'Warning - No Committees Assigned';
            }
            for (let comm of step.committees) {
                if (comm.committee_member_count == 0) {
                    return 'Warning - Task is Not Assigned to Any Users';
                }
            }
            if (this.currentStep == step.id) {
                return 'In Process';
            } else {
                return 'Incomplete';
            }
        },
        formatDate(dateString) {
            if (dateString) {
                return moment(String(dateString)).format('MM/DD/YYYY hh:mm a')
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .error-snackbar.md-snackbar.md-theme-default {
        background-color: #660000;
        max-height: 400px;
        padding: 3px;
    }
    .error-snackbar .md-snackbar-content {
        display: block;
    }
    h3 {
        margin: 40px 0 0;
    }
    .error-snackbar ul {
        list-style-type: none;
        padding: 0;
    }
    .error-snackbar li {
        display: block;
        margin: 3px 3px;
        padding: 5px;
        background-color: #ffcccc;
        color: #660000;
        border-bottom: 1px solid #66000;
        display: flex;
        justify-content: space-between;
    }
    a {
        color: #42b983;
    }
    #case-detail .md-transparent {
        background-color: lightgray !important;
    }
    .details-headline {
        text-align: left;
    }
    .details-headline-right {
        text-align: right;
    }
    .clickable-toolbar {
        cursor: pointer;
    }
    .inline-header-icon {
        padding: 0 0 .25em .5em;
        color: #1c2a4a;
    }
</style>
